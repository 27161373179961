import { PorvenirEnvironment } from '../app/shared/models/environments.model';

/**
 * Configuración del entorno
 */
export const environment: PorvenirEnvironment = {
    production: true,
    googleCaptcha: '6LcIbywkAAAAAIwp42pXYDn1DcF3enmoXZei1hRb',
    signingAesKey: 'F3gLZRh7Z7meR7hQx3f1SOrfgGPxMaGE',
    identityServer: {
    },
    apis: {
      sts: 'undefined',
      acl: 'https://agendamiento.porvenir.com.co/access-control-list/api',
      auth: 'https://agendamiento.porvenir.com.co/auth/api',
      'basic-data': 'https://agendamiento.porvenir.com.co/ws-basic-data/api',
      'engine-availability': 'https://agendamiento.porvenir.com.co/engine-availability/api',
      form: 'https://agendamiento.porvenir.com.co/form/api',
      gender: 'https://agendamiento.porvenir.com.co/gender/api',
      georeference: 'https://agendamiento.porvenir.com.co/georeference/api',
      interaction: 'https://agendamiento.porvenir.com.co/schedule-history/api',
      'interaction-crm': 'https://agendamiento.porvenir.com.co/ws-interaction-crm/api',
      platform: 'https://agendamiento.porvenir.com.co/platform/api',
      position: 'https://agendamiento.porvenir.com.co/position/api',
      'regular-expression': 'https://agendamiento.porvenir.com.co/regular-expression/api',
      'request-visit': 'https://agendamiento.porvenir.com.co/ws-request-visit/api',
      'schedule-claim': 'https://agendamiento.porvenir.com.co/ws-reclamation/api',
      'schedule-history': 'https://agendamiento.porvenir.com.co/schedule-history/api',
      'scheduling-state': 'https://agendamiento.porvenir.com.co/scheduling-states/api',
      'send-ccm': 'https://agendamiento.porvenir.com.co/ws-send-ccm/api',
      'template-time-table': 'https://agendamiento.porvenir.com.co/template-time-table/api',
      'type-document': 'https://agendamiento.porvenir.com.co/type-document/api',
      'type-phone': 'https://agendamiento.porvenir.com.co/type-phone/api',
      typology: 'https://agendamiento.porvenir.com.co/typology/api',
      user: 'https://agendamiento.porvenir.com.co/user/api',
      viability: 'https://agendamiento.porvenir.com.co/ws-viability/api',
      'data-treatment':'https://agendamiento.porvenir.com.co/data-treatment/api',
      segmenter: 'https://agendamiento.porvenir.com.co/ws-segmenter/api'
    },
    environmentName: 'Development',
};
