import { Injectable, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { GenderApiService } from '@prv/gender';
import {
  CityApiService,
  CityDto,
  CountryApiService,
  CountryDto,
  DepartmentApiService,
  NodeApiService,
  NodeDto,
} from '@prv/georeference';
import { ChannelApiService, ChannelDto } from '@prv/platform';
import {
  AdditionalInformation,
  CityContact,
  Contact,
  ProductData,
  Segment,
  ScheduleApiService,
  ScheduleDto,
  TypeDocument,
} from '@prv/schedule-history';
import { StateSchedulingApiService, StateSchedulingDto } from '@prv/scheduling-state';
import { TypeDocumentDto, TypeDocumentsApiService } from '@prv/type-document';
import {
  StructureTypologyApiService,
  StructureTypologyDto,
  TypeTypologyApiService,
  TypeTypologyDto,
} from '@prv/typology';
import { AccountDataDto, BasicDataAffiliateDto, BasicDataApiService, TypologyDto } from '@prv/ws-basic-data';
import { InputsDocumentDto, ResponseDto, ViabilityApiService } from '@prv/ws-viability';
import { DataSegmenterDto, InputsDocumentsDto, SegmenterApiService } from '@prv/ws-segmenter';
import * as _ from 'lodash';
import * as moment from 'moment';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { finalize, map, take, takeUntil } from 'rxjs/operators';

import { AppModuleState } from '../../app.state';
import { DialogModalDto } from '../../core/models/dialog.modal.dto';
import { AuthenticationService } from '../../core/services/auth/authentication.service';
import { IpServiceService } from '../../core/services/ip-service/ip-service.service';
import { ConfigModalsValidation } from '../../shared/dialogs/custom-modal/config/validation/config-modals-validation';
import { DataConfirmModule } from '../../shared/models/data-confirm-module';
import { EncryptService } from '../../shared/services/encrypt/encrypt.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Codes } from '../../modules/Models/Enums/codes.enum';
import { SegmenterCodes } from '../../shared/models/enum-status-segmenter/status-segmenter.enum';
import { AccountStates } from '../../shared/models/enum-account-states/account-states.enum';

/**
 * Capa intermedia que centraliza la logica del Validation
 */
@Injectable()
export class ValidationFacade implements OnDestroy {
  /**
   * Consulta del estado la interacción actual del usuario
   */
  public get state$() {
    return this.state.agentInteractionState$;
  }
  /**
   * Consulta del estado los estados de asignación
   */
  public get stateScheduling() {
    return this.state.scheduleState$;
  }
  /**
   * Consulta del tocken del servicio la bandera de la autenticación del usuario
   */
  public get isAuthenticated() {
    return this.authService.isAuthenticated;
  }
  /**
   * Consulta del tocken del servicio la bandera de la afiliación del usuario
   */
  public get isAffiliate() {
    return this.authService.isAffiliate;
  }
  /**
   * Consulta del tocken del servicio la bandera de la viabilidad del usuario
   */
  public get isViable() {
    return this.authService.isViable;
  }
  /**
   * Consulta del tocken del servicio la bandera de la viabilidad del usuario
   */
  public get error() {
    return this.authService.error;
  }

  /**
   * Spinner Service
   */
  private readonly spinner: NgxSpinnerService;

  /**
   * A service that provides navigation and URL manipulation capabilities.
   */
  private readonly router: Router;
  /**
   * Atributo encargado de obtener la configuración del contenido de la ventana modal
   */
  public readonly configModalsValidation: ConfigModalsValidation;
  /**
   * Servicio ciudades
   */
  private readonly basicDataApiService: BasicDataApiService;
  /**
   * Servicio consulta semanas cotizadas
   */
  private readonly segmenterApiService: SegmenterApiService;
  /**
   * Service de tipologias
   */
  private readonly typeTypologyApiService: TypeTypologyApiService;
  /**
   * Service de estructura tipologias
   */
  private readonly structureTypologyApiService: StructureTypologyApiService;
  /**
   * Servicio para pais
   */
  private readonly countryApiService: CountryApiService;
  /**
   * Servicio para pais
   */
  private readonly departmentApiService: DepartmentApiService;
  /**
   * Servicio ciudades
   */
  private readonly cityApiService: CityApiService;
  /**
   * Servicio encargado de interactuar con el microservicio de georeferencia cuidades
   */
  private readonly nodeApiService: NodeApiService;
  /**
   * Api que permite interactuar con el servicio de tipo de documentos
   */
  private readonly typeDocumentsApiService: TypeDocumentsApiService;
  /**
   * Servicio Generos
   */
  private readonly genderApiService: GenderApiService;
  /**
   * Servicio de History
   */
  private readonly scheduleApiService: ScheduleApiService;
  /**
   * Servicio de History
   */
  private readonly stateSchedulingApiService: StateSchedulingApiService;
  /**
   * Servicio de History
   */
  private readonly channelApiService: ChannelApiService;
  /**
   * Servicio de History
   */
  private readonly viabilityApiService: ViabilityApiService;
  /**
   * Encargado de proveer la ip address
   */
  private readonly httpIp: IpServiceService;
  /**
   * Servicio encargado de controlar la autenticación
   */
  private readonly authService: AuthenticationService;
  /**
   * Servicio encargado de interactuar con el servicio de encriptacion
   */
  private readonly encryptService: EncryptService;
  /**
   * Estados para tipologias
   */
  private readonly state: AppModuleState;
  /**
   * Observable encargado de eliminar los diferentes sucriptores
   */
  private readonly destroy$ = new Subject();
  /**
   * cancelar suscripciones
   */
  private readonly subscription: Subscription;

  /**
   * Observable de los datos basicos del afiliado
   */
  public basicData$ = (): Observable<BasicDataAffiliateDto> => this.state.basicDataAffiliate$;
  /**
   * Observable de tipos de documentos
   */
  public typeDocuments$ = (): Observable<TypeDocumentDto[]> => this.state.typeDocuments$;
  /**
   * Observable de agendamientos
   */
  public schedule$ = (): Observable<ScheduleDto[]> => this.state.schedule$;
  /**
  * Constante que define el codigoViabilidad para no clientes vinculados a otros fondos pensionales
  */
  public CODE_OTHER_AFP: string | boolean = "037";

  /**
   * Crea una nueva instancia de SelectTypologyFacade
   */
  public constructor(
    state: AppModuleState,
    router: Router,
    basicDataApiService: BasicDataApiService,
    typeTypologyApiService: TypeTypologyApiService,
    structureTypologyApiService: StructureTypologyApiService,
    countryApiService: CountryApiService,
    cityApiService: CityApiService,
    departmentApiService: DepartmentApiService,
    nodeApiService: NodeApiService,
    typeDocumentsApiService: TypeDocumentsApiService,
    genderApiService: GenderApiService,
    scheduleApiService: ScheduleApiService,
    stateSchedulingApiService: StateSchedulingApiService,
    channelApiService: ChannelApiService,
    viabilityApiService: ViabilityApiService,
    httIp: IpServiceService,
    authService: AuthenticationService,
    encryptService: EncryptService,
    segmenterApiService: SegmenterApiService,
    spinner: NgxSpinnerService,
  ) {
    this.spinner = spinner;
    this.state = state;
    this.router = router;
    this.configModalsValidation = new ConfigModalsValidation();
    this.basicDataApiService = basicDataApiService;
    this.typeTypologyApiService = typeTypologyApiService;
    this.structureTypologyApiService = structureTypologyApiService;
    this.departmentApiService = departmentApiService;
    this.cityApiService = cityApiService;
    this.countryApiService = countryApiService;
    this.nodeApiService = nodeApiService;
    this.typeDocumentsApiService = typeDocumentsApiService;
    this.genderApiService = genderApiService;
    this.scheduleApiService = scheduleApiService;
    this.stateSchedulingApiService = stateSchedulingApiService;
    this.channelApiService = channelApiService;
    this.viabilityApiService = viabilityApiService;
    this.httpIp = httIp;
    this.subscription = new Subscription();
    this.authService = authService;
    this.encryptService = encryptService;
    this.segmenterApiService = segmenterApiService;
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately
   * before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroySubscription();
    setTimeout(() => {
      this.destroy$.next();
      this.destroy$.complete();
    }, 3000);
  }

  /**
   * Metodo encargado de desuscribir los observables de la facade
   */
  public destroySubscription(): void {
    this.subscription.unsubscribe();
  }
  /**
   * Se encarga de autenticar el cliente mediante googleToken
   * @param data contenido del formulario Dinamico @see any
   * @param typeDocument contenido de tipos de documentos @see TypeDocumentDto
   * @param url URL del modulo actual @see string
   * @param fn Funcion Callback
   */
  public authenticateClientAuth(
    data: any,
    typeDocument: TypeDocumentDto | undefined,
    url: string,
    fn: (response: DataConfirmModule) => void,
  ): void {
    this.authService
      .authenticate(data.captcha, data.numberDocument, typeDocument?.id as number, typeDocument?.mask as string)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          let objModal: DialogModalDto;

          if (this.isAuthenticated) {
            this.state.setIsAffiliate(this.isAffiliate);
            this.state.setIsViable(this.isViable);
            if (!this.isAffiliate) {
              const dataViability: InputsDocumentDto = {
                idTypeDocument: typeDocument?.id,
                typeDocument: typeDocument?.mask,
                numberDocument: data.numberDocument
              }
              this.getViability(dataViability, (res) => {
                if (res?.status?.statusCode === 200) {

                  // Guarda la respuesta del servicio en el state
                  this.setViability(res);

                  // Flag que valida si el no cliente pertenece a otro fondo pensional
                  const hasOtherAFP = res.viabilidad?.codigoViabilidad === this.CODE_OTHER_AFP;
                  const isViable = res.viabilidad?.esViable

                  this.spinner.hide();

                  // Valida la flag, muestra el modal y saca al no cliente del servicio
                  if (hasOtherAFP) {
                    objModal = {
                      data: this.configModalsValidation.modalOtherAFP(undefined),
                      width: '720px',
                      height: '400px',
                      panelClass: 'dialog-container',
                      autoFocus: false,
                      restoreFocus: false,
                      disableClose: true,
                    };
                    fn({ objModal, routes: ['/'] });
                    return;
                  }

                  // Valida si el no cliente es viable para direccionarlo a la linea de servicio
                  if (isViable) {
                    objModal = {
                      data: this.configModalsValidation.modalLineAttentionDA(undefined),
                      width: '720px',
                      height: '430px',
                      panelClass: 'dialog-container',
                      autoFocus: false,
                      restoreFocus: false,
                      disableClose: true,
                    };
                    fn({ objModal, routes: ['/'] });
                    return;
                  }

                  this.state.setIsViable(
                    // eslint-disable-next-line
                    res?.viabilidad?.esViable !== null && res?.viabilidad?.esViable !== undefined
                      ? res?.viabilidad?.esViable
                      : false,
                  );

                } else {
                  this.spinner.hide();
                  objModal = {
                    data: this.configModalsValidation.modalNoData(undefined),
                    width: '720px',
                    height: '420px',
                    panelClass: 'dialog-container',
                    autoFocus: false,
                    restoreFocus: false,
                    disableClose: true,
                  };
                  fn({ objModal, routes: ['/'] });
                }
              });
            } else {
              this.redirectPath(data, typeDocument, url, (response) => {
                fn(response);
              });
            }
          } else {
            console.log('Inconveniente [authenticateClientAuth]');
            fn({ routes: ['/'] });
          }
        }),
      )
      .subscribe();
  }

  /**
   * Metodo encargado de mostrar el modal si no tiene información en la fecha de nacimiento.
   */
  public showCustomModal(statusCode: number) {

    this.spinner.hide();
    //Variable para almacenar el statusCode
    let modalContent = '';

    //Validamos el estado recibido para definir el mensaje que se debe mostrar en el modal
    if (statusCode === Codes.onlyProduct) {
      modalContent = `<p class="text-style" style="font-size: 22px; color: #4c7235 !important; font-weight: bold"><br />Le informamos que este servicio está únicamente disponible para la asignación de citas relacionadas con productos de Pensiones. Si desea realizar una solicitud de Cesantías, lo invitamos a visitar nuestros canales de servicio en nuestra página web: <a href="https://www.porvenir.com.co/web/canales-de-servicio" target="_blank" style="color: orange;">Todos los canales de servicio - Canales de servicio (porvenir.com.co)</a></p>`;
    } else if (statusCode === Codes.emptyBirthday) {
      modalContent = `<p class="text-style" style="font-size: 22px; color: #4c7235 !important; font-weight: bold"><br />Le solicitamos por favor dirigirse a alguna de nuestras oficinas para completar su información personal y así programar su cita: <a href="https://www.porvenir.com.co/web/canales-de-servicio/oficinas-porvenir" target="_blank" style="color: orange;">Nuestras oficinas - Canales de servicio (porvenir.com.co)</a></p>`;
    }

    Swal.fire({
      customClass: {
        closeButton: 'button-close-global',
        popup: 'modal-content',
        confirmButton: 'button-next',
      },
      imageUrl: './assets/images/warning.png',
      imageWidth: '120',
      imageHeight: '120',
      imageAlt: 'Warning',
      html: modalContent,
      width: '750',
      heightAuto: true,
      showCloseButton: true,
      confirmButtonText: 'Aceptar',
      allowOutsideClick: false,
    }).then(() => {
      this.router.navigate(['/']);
    });
  }

  /**
   * Metodo que valida el statsuCode del segmentador y retorna el modal necesario
   * @param statusCode código de estado a validar
   */
  public segmenterStatusValidator(statusCode: number | undefined) {
    let message = '';

    // Valida el statusCode del servicio y define el mensaje a enviar
    if (statusCode === SegmenterCodes.CLIENT_NOT_FOUND) {
      message = `<p class="text-style"><br />Lamentamos informarte que no fue posible validar tu información pensional; por favor contáctanos a través de nuestra Línea de Servicio: </br> Bogotá: 601 744 7678 </br> Cali: 602 485 72 72 </br> Barranquilla: 605 385 51 51 </br> Medellín: 604 604 15 55 </br> Resto de Colombia: 01 8000 510 800 </br> Resto de Colombia desde celular #857</p>`;
    } else {
      message = `<p class="text-style"><br />03. Estamos experimentando una falla técnica con nuestro servicio, por favor intenta más tarde.</p>`;
    }

    this.spinner.hide();

    // Representa los parametros del modal
    Swal.fire({
      customClass: {
        closeButton: 'button-close-global',
        popup: 'modal-content',
        confirmButton: 'button-next',
      },
      imageUrl: './assets/images/warning.png',
      imageWidth: '120',
      imageHeight: '120',
      imageAlt: 'Warning',
      html: message,
      width: '750',
      heightAuto: true,
      showCloseButton: true,
      confirmButtonText: 'Aceptar',
      allowOutsideClick: false,
    }).then(() => {
      this.router.navigate(['/']);
    });
  }

  /**
   * Se encarga de redireccionar la aplicacion segun el modulo
   * @param data contenido del formulario Dinamico @see any
   * @param typeDocument contenido de tipos de documentos @see TypeDocumentDto
   * @param url URL del modulo actual @see string
   * @param fn Funcion Callback @see DataConfirmModule
   */
  private redirectPath(
    data: any,
    typeDocument: TypeDocumentDto | undefined,
    url: string,
    fn: (response: DataConfirmModule) => void,
  ) {
    this.BuildInitial(typeDocument, data.numberDocument || '', url, (tipologies) => {
      let objModal: DialogModalDto;
      if (!this.getBasic()) {
        objModal = {
          data: this.configModalsValidation.modalNoData(undefined),
          width: '720px',
          height: '420px',
          panelClass: 'dialog-container',
          autoFocus: false,
          restoreFocus: false,
          disableClose: true,
        };
        fn({ objModal, routes: ['/'] });
      } else {
        // Define el codigo de estado de la respuesta del servicio
        const statusBasicData = this.getBasic().status?.statusCode;

        // Valida los casos para fecha de nacimiento y único producto cesantías
        if (statusBasicData === Codes.emptyBirthday || statusBasicData === Codes.onlyProduct) {
          this.showCustomModal(statusBasicData);
          return;
        }

        // Construye el objeto para el servicio segmentador
        const dataRequest: InputsDocumentsDto = {
          typeDocument: typeDocument?.mask,
          numberDocument: data.numberDocument
        };

        // Define el estado de cuenta del cliente
        const stateAccount = this.getBasic().datosCuenta?.estadoCuenta;
        // Valida el estado de cuenta del afiliado para los diferentes casos
        const isBenefits = stateAccount === AccountStates.PRESTACION_DEFINIDA || stateAccount === AccountStates.PRESTACION_ENTRAMITE;

        // Si no cumple con la codición de estado de cuenta no consume el segmentador
        if (!isBenefits) {
          // Realiza consumo del servicio segmentador (Semanas cotizadas)
          this.getSegmenterData(dataRequest, (response) => {
            this.setSegmenterData(response);
          });
        }

        switch (url) {
          case '/validation-assignment':
            fn({ objModal: undefined, routes: ['/assignment-module'] });
            break;
          case '/validation-search':
            this.getScheduleSt((response) => {
              if (response === undefined) {
                fn({ objModal: response, routes: ['/assistant', 'validation-search'] });
              } else {
                fn({ objModal: response, routes: ['/'] });
              }
            });
            break;
          case '/validation-modify-cancel':
            this.getScheduleSt((response) => {
              if (response === undefined) {
                fn({ objModal: response, routes: ['/assistant', 'validation-modify-cancel'] });
              } else {
                fn({ objModal: response, routes: ['/'] });
              }
            });
            break;
          default:
            fn({ objModal: undefined, routes: ['/'] });
        }

        if (tipologies.length <= 0) {
          if (localStorage.getItem('clientName') === null) {
            objModal = {
              data: this.configModalsValidation.modalNoData(undefined),
              width: '720px',
              height: '420px',
              panelClass: 'dialog-container',
              autoFocus: false,
              restoreFocus: false,
              disableClose: true,
            };
          } else {
            objModal = {
              data: this.configModalsValidation.modalWithoutTypologies(),
              width: '720px',
              height: '380px',
              panelClass: 'dialog-container',
              autoFocus: false,
              restoreFocus: false,
              disableClose: true,
            };
          }
          fn({ objModal, routes: ['/'] });
        }
      }
    });
  }
  /**
   * Construye todos los estados necesarios para la app no asistido
   * @param typeDocument id tipo de documento @see TypeDocumentDto
   * @param documentNumber numero de documento @see string
   * @param url URL en la que se encuentra
   * @param fn Funcion Callback
   */
  private BuildInitial(
    typeDocument: TypeDocumentDto | undefined,
    documentNumber: string,
    url: string,
    fn: (tipologies: TypologyDto[]) => void,
  ) {
    this.resetInteraction();

    const object = forkJoin([
      this.getTypeTypologies(),
      this.getStructureTypologies(),
      this.getBasicData(),
      this.getCountries(),
      this.getSchedule(url),
      this.getSchedulingState(),
    ]);

    const objectOne = forkJoin([
      this.getTypeDocuments(),
      this.getCitykeyAndValue(),
      this.getDepartmentskeyAndValue(),
      this.getGenderkeyAndValue(),
      this.getChannel(),
    ]);

    const objectTwo = forkJoin([this.getNodesByChannelAndIdCountry([2, 3, 5], 170)]);

    forkJoin([object, objectOne, objectTwo])
      .pipe(
        finalize(() => {
          fn(this.getTypologies());
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        ([
          [typeTypologies, structureTypologies, basicData, countries, schedules, scheduleState],
          [typeDocuments, citiesKey, departmentsKey, genderKey, channel],
          [nodesAll],
        ]) => {
          this.state.setTypeTypologies(typeTypologies);
          this.state.setStructureTypology(structureTypologies);
          this.state.setBasicDataAfiliate(basicData);
          this.state.setCountries(countries);
          this.state.setSchedules(schedules);
          this.state.setStateLive(scheduleState);
          this.state.setSchedulesState(scheduleState);
          this.state.setTypeDocuments(typeDocuments);
          this.state.setCitiesKeyAndValue(citiesKey);
          this.state.setDepartmentsKeyAndValue(departmentsKey);
          this.state.setGendersKeyAndValue(genderKey);
          this.state.setChannel(channel);
          this.state.setNodesAll(nodesAll);
          const datos = basicData ? basicData : {};

          const state = this.state.value;
          const dateNow = moment(new Date()).locale('es').format('YYYY-MM-DD[T]HH:mm:ss');
          state.payloadScheduling.dateCall = dateNow;
          state.payloadScheduling.contact = _.cloneDeep(this.buildContact(datos, typeDocument, documentNumber));
          this.state.setContact(state);

          // This.state.setIsAffiliate(this.isAffiliate);
          // This.state.setIsViable(this.isViable);
          // eslint-disable-next-line
          const tipologias = basicData !== null ? basicData.tipologias : [];
          this.state.setTypologiesFromCRM(tipologias || []);
        },
      );
  }
  /**
   * Metodo que construye el objeto de informacion basica contacto para almacenarla en el estado
   * @param data informacion obtenida del ws datos basicos @see BasicDataAffiliateDto
   * @param typeDocument id tipo documento @see number
   * @param documentNumber numero del documento @see string
   */
  private buildContact(data: BasicDataAffiliateDto, typeDocument: TypeDocumentDto | undefined, documentNumber: string) {
    const dataAccount: AccountDataDto = {
      idCuenta: data.datosCuenta?.idCuenta || undefined,
      estadoCuenta: data.datosCuenta?.estadoCuenta,
      estadoSubCuenta: data.datosCuenta?.estadoSubCuenta,
    };
    const dataTypeDocument: TypeDocument = {
      id: typeDocument?.id || 0,
      mark: typeDocument?.mask || '',
      name: typeDocument?.name || '',
    };
    const city: CityContact = {};
    const cityCode =
      (data.datosContacto?.codigoCiudad?.length || 0) > 3
        ? data.datosContacto?.codigoCiudad?.substr(data.datosContacto?.codigoCiudad?.length - 3, 3) || '0'
        : data.datosContacto?.codigoCiudad || '0';

    if (cityCode !== '0') {
      this.subscription.add(
        this.getCityByCode(cityCode)
          .pipe(
            map((cityByCode) => {
              if (cityByCode) {
                city.id = Number(cityByCode.id || '0') || 0;
                city.name = cityByCode.name || '';
                city.idDepartment = Number(cityByCode.department?.id || '0') || 0;
                city.department = cityByCode.department?.name || '';
              }
            }),
            takeUntil(this.destroy$),
          )
          .subscribe(),
      );
    }

    const segment: Segment = {
      name: data?.datosSegmento?.segmento,
    };
    const productDataArray = data.datosProducto || [];
    const productData: ProductData = {
      name: (productDataArray.length || 0) > 0 ? productDataArray[0].producto : undefined,
    };
    const additionalInformation: AdditionalInformation = {
      productData,
      segment,
    };
    const basicData: Contact = {
      idTypeDocument: typeDocument?.id || 0,
      numberDocument: documentNumber,
      fullName: data.datosBasicos?.nombresApellidosCompletos,
      idGender: Number(data.datosBasicos?.genero?.replace('F', '1').replace('M', '2') || '0'),
      gender: data.datosBasicos?.genero?.replace('F', 'Femenino').replace('M', 'Masculino'),
      birthdate: moment(data.datosBasicos?.fechaNacimiento, 'DD/MM/YYYY').toDate(),
      cellPhones: [data.datosContacto?.celular?.toString() || ''],
      localPhones: [data.datosContacto?.telefono?.toString() || ''],
      emails: [data.datosContacto?.correoElectronico?.toString() || ''],
      address: [data.datosContacto?.direccion?.toString() || ''],
      habeasData: true,
      additionalInformation,
      typeDocument: dataTypeDocument,
      city,
    };
    this.setDataAccount(dataAccount);

    return basicData;
  }
  /**
   * Metodo que obtiene los tipo de documento del estado
   * @param id Id Tipo documento @see number
   * @param fn Funcion Callback @see TypeDocumentDto
   */
  public getTypeDocumentsByIdSt(id: number, fn: (response: TypeDocumentDto | undefined) => void) {
    this.subscription.add(
      this.typeDocuments$()
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          const result = response.find((x) => Number(x.id) === Number(id)) || undefined;
          fn(result);
        }),
    );
  }
  /**
   * Metodo que obtiene los agendamientos del estado
   * @param fn Funcion Callback @see DialogModalDto
   */
  private getScheduleSt(fn: (objModal: DialogModalDto | undefined) => void) {
    this.subscription.add(
      this.schedule$()
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          if (result.length <= 0) {
            fn({
              data: this.configModalsValidation.modalNoData(undefined),
              width: '720px',
              height: '420px',
              panelClass: 'dialog-container',
              autoFocus: false,
              restoreFocus: false,
              disableClose: true,
            });
          } else {
            fn(undefined);
          }
        }),
    );
  }
  /**
   * Metodo que consume el servicio de Datos Basicos
   * @returns Observable de tipo @see Observable<BasicDataAffiliateDto>
   */
  private getBasicData(): Observable<BasicDataAffiliateDto> {
    return this.basicDataApiService.getBasicDataAuthRequestExternal().pipe(take(1), takeUntil(this.destroy$));
  }
  /**
   * Metodo que consulta los tipos de tipologias
   * @returns Observable de los tipos de tipologia con tipo @see TypeTypologyDto[]
   */
  private getTypeTypologies(): Observable<TypeTypologyDto[]> {
    return this.typeTypologyApiService.all().pipe(
      take(1),
      map((data) => data.filter((x) => x.state === true)),
      takeUntil(this.destroy$),
    );
  }
  /**
   * Metodo que consulta la estrucutra de tipologia en estado activo
   * @returns Observable de la estructura de tipologia con tipo @see StructureTypologyDto
   */
  private getStructureTypologies(): Observable<StructureTypologyDto> {
    return this.structureTypologyApiService.getStructureActive().pipe(take(1), takeUntil(this.destroy$));
  }
  /**
   * Metodo que consulta todos los Paises en estado activo
   * @returns Observable de las paises con tipo @see CountryDto[]
   */
  private getCountries(): Observable<CountryDto[]> {
    return this.countryApiService.all().pipe(
      take(1),
      map((data) => data.filter((x) => x.state === true)),
      takeUntil(this.destroy$),
    );
  }
  /**
   * Metodo que consulta key/Value de los Departamentos en estado activo
   * @returns Observable de los departamentos con tipo key/value
   */
  private getDepartmentskeyAndValue(): Observable<{ [id: number]: string }> {
    return this.departmentApiService.keyValue(true).pipe(take(1), takeUntil(this.destroy$));
  }
  /**
   * Metodo que consulta key/Value de las ciudades en estado activo
   * @returns Observable de las ciudades con tipo key/value
   */
  private getCitykeyAndValue(): Observable<{ [id: number]: string }> {
    return this.cityApiService.keyValue(true).pipe(take(1), takeUntil(this.destroy$));
  }
  /**
   * Metodo que consulta la ciudad por codigo
   * @param code Codigo de la ciudad a consultar @see srtring
   * @returns Observable de la ciudad con tipo @see CityDto
   */
  private getCityByCode(code: string): Observable<CityDto> {
    return this.cityApiService.getCityByCode(code, true).pipe(take(1), takeUntil(this.destroy$));
  }
  /**
   * Metodo que consulta los Nodos por liasta de canal, codigo de pais y estado activo
   * @param codsChannel Lista de codigos de canal a consultar ([2, 3]) @see number[]
   * @param idCountry Id del pais a consultar (170) @see number
   * @returns Observable de los nodos con tipo @see NodeDto[]
   */
  private getNodesByChannelAndIdCountry(codsChannel: number[], idCountry: number): Observable<NodeDto[]> {
    return this.nodeApiService
      .getNodesByCodesChannelIdCountry({ codsChannel, idCountry, state: true })
      .pipe(take(1), takeUntil(this.destroy$));
  }
  /**
   * Metodo que consulta todos los tipos de documentos en estado activo
   * @returns Observable de los tipos de documento con tipo @see TypeDocumentDto[]
   */
  private getTypeDocuments(): Observable<TypeDocumentDto[]> {
    return this.typeDocumentsApiService.all().pipe(
      take(1),
      map((data) => data.filter((x) => x.state === true)),
      takeUntil(this.destroy$),
    );
  }
  /**
   * Metodo que consulta todos los tipos de documentos en estado activo
   */
  public getTempTypeDocuments(fn: (response: TypeDocumentDto[]) => void) {
    this.typeDocumentsApiService
      .all()
      .pipe(
        take(1),
        map((data) => data.filter((x) => x.state === true)),
        takeUntil(this.destroy$),
      )
      .subscribe((response) => {
        fn(response);
      });
  }
  /**
   * Metodo que consulta todos los generos en estado activo
   * @returns Observable de los generos con tipo key/value
   */
  private getGenderkeyAndValue(): Observable<{ [id: number]: string }> {
    return this.genderApiService.keyValue(true).pipe(take(1), takeUntil(this.destroy$));
  }
  /**
   * Metodo que consulta todo los agendamientos segun el numero de documento
   * obtenido en la autenticación
   * @param url URL de la opcion a ejecutar (crear, modificar, consultar)  @see string
   * @returns Observable de los agendamientos con tipo @see ScheduleDto[]
   */
  private getSchedule(url: string): Observable<ScheduleDto[]> {
    switch (url) {
      case '/validation-search':
        return this.scheduleApiService.getByNumberDocumentAuth().pipe(
          take(1),
          map((response) => {
            const dataDesencrypted = this.encryptService.toDecrypt(response.data || '');
            const dataSchedules = JSON.parse(dataDesencrypted) as ScheduleDto[];
            return dataSchedules;
          }),
          takeUntil(this.destroy$),
        );
        break;
      case '/validation-modify-cancel':
        return this.scheduleApiService.getByNumberDocumentAuth().pipe(
          take(1),
          map((response) => {
            const dataDesencrypted = this.encryptService.toDecrypt(response.data || '');
            const dataSchedules = JSON.parse(dataDesencrypted) as ScheduleDto[];
            const result = dataSchedules.filter((x) => x.idStateScheduling !== 3 && x.idStateScheduling !== 4);
            return result;
          }),
          takeUntil(this.destroy$),
        );
        break;
      default:
        return this.scheduleApiService.getByNumberDocumentAuth().pipe(
          take(1),
          map((response) => {
            const dataDesencrypted = this.encryptService.toDecrypt(response.data || '');
            const dataSchedules = JSON.parse(dataDesencrypted) as ScheduleDto[];
            const result = dataSchedules.filter((x) => x.idStateScheduling !== 3);
            return result;
          }),
          takeUntil(this.destroy$),
        );
    }
  }
  /**
   * Metodo que consulta todo los canales en estado activo
   * @returns Observable de los canales con tipo @see ChannelDto[]
   */
  private getChannel(): Observable<ChannelDto[]> {
    return this.channelApiService.all().pipe(
      take(1),
      map((data) => data.filter((x) => x.state === true), takeUntil(this.destroy$)),
    );
  }
  /**
   * Metodo que consulta todos los agendamiento en estado vivo
   * @returns Observable de los estados de agendamiento con tipo @see StateSchedulingDto[]
   */
  private getSchedulingState(): Observable<StateSchedulingDto[]> {
    return this.stateSchedulingApiService.GetByStateLive(true).pipe(take(1), takeUntil(this.destroy$));
  }
  /**
   * Método encargado de obtener la viabilidad
   * @param input Objeto con información del documento de identificación del afiliado
   * @param fn Callback al ejecutarse el metodo
   */
  public getViability(input: InputsDocumentDto, fn: (viability: ResponseDto) => void) {
    this.viabilityApiService.getViability(input)
      .pipe(
        take(1),
        takeUntil(this.destroy$),
      ).subscribe((response) => {
        fn(response);
      });
  }
  /**
 * Metodo que consulta el servicio de semanas cotizadas
 * @param data Datos de documento del afilaido @see DataSegmenterDto
 * @param fn Funciion CallBack @see ResponseDto
 */
  private getSegmenterData(data: InputsDocumentsDto, fn: (response: DataSegmenterDto) => void) {
    this.segmenterApiService
      .getSegmenterData(data)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((response: DataSegmenterDto) => {
        fn(response);
      }, (error: HttpErrorResponse) => {
        if (error.status !== 200) {
          this.segmenterStatusValidator(error.status);
        }
      });
  }
  /**
   * Metodo encargado de almacenar en el estado los datos de la viabilidad
   * @param data Datos de la viabilidad @see ResponseDto
   */
  private setViability(data: ResponseDto) {
    this.state.setViability(data);
  }
  /**
 * Metodo encargado de almacenar en el estado los datos del segmentador
 * @param data Datos del segmentador @see DataSegmenterDto
 */
  private setSegmenterData(data: DataSegmenterDto) {
    this.state.setSegmenterData(data);
  }
  /**
   * Metodo que consulta el codigo del estado de ws datos basicos
   * @param fn Fuancion CallBack @see number
   */
  public getStatusCode(fn: (response: number) => void) {
    this.subscription.add(
      this.state.basicDataAffiliate$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
        fn(response.status?.statusCode || 0);
      }),
    );
  }
  /**
   * Metodo que consulta el listado de tipologias del estado de ws datos basicos
   * @returns Listado de tipologias con tipo @see TypologyDto[]
   */
  private getTypologies(): TypologyDto[] {
    let params: TypologyDto[] = [];
    this.subscription.add(this.state.basicDataAffiliate$
      .pipe(
        map((tipologies) => {
          // eslint-disable-next-line
          if (tipologies !== null) {
            params = tipologies.tipologias || [];
          }
          if (params.length === 0) {
            const a: TypologyDto = {
              codigo: 2,
              indicadorValidarTipologia: '2',
              nombre: 'DOBLE ASESORÍA',
              tiempoAtencion: '30 minutos',
            };
            params.push(a);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe());

    return params;
  }
  /**
   * Metodo encargado de actualizar el estado con los tipos de documento
   * @param typeDocuments Lista de tipos de documento a almacenar @see TypeDocumentDto[]
   */
  public setTypeDocumentsSt(typeDocuments: TypeDocumentDto[]) {
    this.state.setTypeDocuments(typeDocuments);
  }
  /**
   * Metodo que actualiza los datos del contacto en el estado
   * @param dataAccount Objeto con los datos del contacto de tipo @see AccountDataDto
   */
  public setDataAccount(dataAccount: AccountDataDto) {
    this.state.setDataAccount(dataAccount);
  }
  /**
   * Metodo que inicializa el estado interaction en valores nulos
   */
  public resetInteraction() {
    this.state.resetInteraction();
  }
  /**
   * Metodo que obtiene los datos de basic data
   */
  public getBasic(): BasicDataAffiliateDto {
    return this.state.basicDataAffiliateObject;
  }
  /**
   * Metodo que obtiene la ip address
   */
  public getIP() {
    this.httpIp.getIPAddress();
  }
}
