import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DetailReasonStateSchedulingDto } from '@prv/scheduling-state';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

import { ValidatorMessageService } from '../../../core/services/validator-message/validator-message.service';
import { ITypeMessage } from '../../models/type-message.model';
import { CustomModalFacade } from '../facade/custom-modal.facade';

import { ConfigModalsModifyCancel } from './config/modify-cancel/config-modals-modify-cancel';
import { CustomModalModel } from './models/custom-modal.model';

/**
 * Componente de ventanas modales personalizadas
 */
@Component({
  selector: 'prv-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss'],
})
export class CustomModalComponent implements OnInit {
  /**
   * Configuración interna de la ventana modal
   */
  @Inject(MAT_DIALOG_DATA) public config: CustomModalModel;
  /**
   * Servicio pantalla carga
   */
  private readonly spinner: NgxSpinnerService;
  /**
   * Control de paginación
   */
  private readonly location: Location;
  /**
   * Capa intermedia que centraliza la logica del agente
   */
  private readonly facade: CustomModalFacade;
  /**
   * Provides access to information about a route associated with a component that is loaded in an outlet.
   */
  private readonly router: Router;
  /**
   * Observable con información de los Estados
   */
  public readonly detailReasonStateScheduling$: Observable<DetailReasonStateSchedulingDto[]>;

  /**
   * Atributo encargado de obtener la configuración del contenido de la ventana modal
   */
  public configModalsModifyCancel: ConfigModalsModifyCancel;
  /**
   * Permite desplegar un modal
   */
  public dialog: MatDialog;
  /**
   * Formulario de Validación
   */
  public form: UntypedFormGroup;
  /**
   * listado de mensajes de error
   */
  public typeMessages: ITypeMessage[];
  /**
   * Url del micrositio de porvenir para reforma pensional
   */
  public urlMicrosite: string = "https://www.porvenir.com.co/web/porvenir/porvenir-te-responde/reforma-pensional";

  /**
   * Crea una nueva instancia del componente
   * @param config Configuración interna de la ventana modal
   */
  public constructor(
    @Inject(MAT_DIALOG_DATA) config: CustomModalModel,
    spinner: NgxSpinnerService,
    location: Location,
    builder: UntypedFormBuilder,
    dialog: MatDialog,
    router: Router,
    facade: CustomModalFacade,
    typeMessages: ValidatorMessageService,
  ) {
    this.spinner = spinner;
    this.config = config;
    this.form = builder.group({
      optionCancel: ['', Validators.required],
    });
    this.location = location;
    this.dialog = dialog;
    this.router = router;
    this.configModalsModifyCancel = new ConfigModalsModifyCancel();
    this.facade = facade;
    this.detailReasonStateScheduling$ = this.facade.detailReasonStateScheduling$();
    this.typeMessages = typeMessages.typeMessage;
  }

  /**
   * Inicializador del componente
   */
  public ngOnInit(): void {
    this.loadConfigurationScssPropertiesTitle();
    this.loadConfigurationScssPropertiesImage();
    this.loadConfigurationScssPropertiesTextContent();
    this.loadConfigurationScssPropertiesButtonClose();
    this.loadConfigurationScssPropertiesButtons();
  }
  /**
   * Metodo que captura los eventos de click de boton en los modal
   * @param option Opcion del tipo de boton @see string
   * @returns Retorna indicador de bandera del resultado final @see boolean
   */
  public onSubmit(option: string | undefined = '') {
    this.spinner.show();
    switch (option) {
      case 'Cancelar':
        if (this.form.invalid) {
          this.openModalCancel();

          return false;
        }
        const data = this.form.value;
        const optionCancel = data.optionCancel.name;
        localStorage.setItem('OptionCancel', JSON.stringify(optionCancel));
        this.facade.cancelShedule(() => {
          this.facade.setWsinteraction();
          this.spinner.hide();
          this.openModalCancelApproved();
          this.router.navigate(['/']);
        });
        break;
      case 'Modificar':
        const datos = this.parseJson(localStorage.getItem('Datos') || '');
        localStorage.setItem('idSchedule', datos.id);
        this.spinner.hide();
        this.router.navigate(['/assignment-module', 'typology-modify']);
        break;
      case 'Volver':
        this.spinner.hide();
        this.location.back();
        break;
      case 'Salir':
        this.spinner.hide();
        this.router.navigate(['/']).then(() => {
          window.location.reload();
        });
        break;
      case 'Micrositio':
        this.router.navigate(['/']).then(() => {
          window.open(this.urlMicrosite, '_blank');
          this.spinner.hide();
        });
        break;
      default:
        this.spinner.hide();
    }
  }
  /**
   * Función encargada de cargar la configuración de las propiedades Scss del titulo de las ventanas modal
   */
  private loadConfigurationScssPropertiesTitle() {
    document.documentElement.style.setProperty('--size-titleModalXl', this.config.sizeTitleModalXl || null);
    document.documentElement.style.setProperty('--size-titleModalLg', this.config.sizeTitleModalLg || null);
    document.documentElement.style.setProperty('--size-titleModalMd', this.config.sizeTitleModalMd || null);
    document.documentElement.style.setProperty('--size-titleModalSm', this.config.sizeTitleModalSm || null);
    document.documentElement.style.setProperty('--size-titleModalXs', this.config.sizeTitleModalXs || null);
    document.documentElement.style.setProperty('--size-titleModalXss', this.config.sizeTitleModalXss || null);
    document.documentElement.style.setProperty('--top-titleGapValue', this.config.topTitleGapValue || null);
    document.documentElement.style.setProperty('--bottom-titleGapValue', this.config.bottomTitleGapValue || null);
  }

  /**
   * Función encargada de cargar la configuración de las propiedades Scss del texto de contenido de las ventanas modal
   */
  private loadConfigurationScssPropertiesTextContent() {
    document.documentElement.style.setProperty('--size-textContentModalXl', this.config.sizeTextContentModalXl || null);
    document.documentElement.style.setProperty('--size-textContentModalLg', this.config.sizeTextContentModalLg || null);
    document.documentElement.style.setProperty('--size-textContentModalMd', this.config.sizeTextContentModalMd || null);
    document.documentElement.style.setProperty('--size-textContentModalSm', this.config.sizeTextContentModalSm || null);
    document.documentElement.style.setProperty('--size-textContentModalXs', this.config.sizeTextContentModalXs || null);
    document.documentElement.style.setProperty(
      '--size-textContentModalXss',
      this.config.sizeTextContentModalXss || null,
    );
    document.documentElement.style.setProperty('--top-textContentGapValue', this.config.topTextContentGapValue || null);
    document.documentElement.style.setProperty(
      '--bottom-textContentGapValue',
      this.config.bottomTextContentGapValue || null,
    );
  }

  /**
   * Función encargada de cargar la configuración de las propiedades Scss de la imagen de contenido de las ventanas modal
   */
  private loadConfigurationScssPropertiesImage() {
    document.documentElement.style.setProperty('--width-imageXl', this.config.widthImageXl || null);
    document.documentElement.style.setProperty('--width-imageLg', this.config.widthImageLg || null);
    document.documentElement.style.setProperty('--width-imageMd', this.config.widthImageMd || null);
    document.documentElement.style.setProperty('--width-imageSm', this.config.widthImageSm || null);
    document.documentElement.style.setProperty('--width-imageXs', this.config.widthImageXs || null);
    document.documentElement.style.setProperty('--width-imageXss', this.config.widthImageXss || null);
    document.documentElement.style.setProperty('--height-imageXl', this.config.heightImageXl || null);
    document.documentElement.style.setProperty('--height-imageLg', this.config.heightImageLg || null);
    document.documentElement.style.setProperty('--height-imageMd', this.config.heightImageMd || null);
    document.documentElement.style.setProperty('--height-imageSm', this.config.heightImageSm || null);
    document.documentElement.style.setProperty('--height-imageXs', this.config.heightImageXs || null);
    document.documentElement.style.setProperty('--height-imageXss', this.config.heightImageXss || null);
    document.documentElement.style.setProperty('--top-imageGapValue', this.config.topImageGapValue || null);
    document.documentElement.style.setProperty('--bottom-imageGapValue', this.config.bottomImageGapValue || null);
  }

  /**
   * Función encargada de cargar la configuración de las propiedades Scss del botón de cerrar de las ventanas modal
   */
  private loadConfigurationScssPropertiesButtonClose() {
    document.documentElement.style.setProperty(
      '--buttonClose-PosAxisXl',
      this.config.closeCornerButtonPosAxisXl || null,
    );
    document.documentElement.style.setProperty(
      '--buttonClose-PosAxisLg',
      this.config.closeCornerButtonPosAxisLg || null,
    );
    document.documentElement.style.setProperty(
      '--buttonClose-PosAxisMd',
      this.config.closeCornerButtonPosAxisMd || null,
    );
    document.documentElement.style.setProperty(
      '--buttonClose-PosAxisSm',
      this.config.closeCornerButtonPosAxisSm || null,
    );
    document.documentElement.style.setProperty(
      '--buttonClose-PosAxisXs',
      this.config.closeCornerButtonPosAxisXs || null,
    );
    document.documentElement.style.setProperty(
      '--buttonClose-PosAxisXss',
      this.config.closeCornerButtonPosAxisXss || null,
    );
    document.documentElement.style.setProperty(
      '--buttonClose-PosCrossXl',
      this.config.closeCornerButtonPosCrossXl || null,
    );
    document.documentElement.style.setProperty(
      '--buttonClose-PosCrossLg',
      this.config.closeCornerButtonPosCrossLg || null,
    );
    document.documentElement.style.setProperty(
      '--buttonClose-PosCrossMd',
      this.config.closeCornerButtonPosCrossMd || null,
    );
    document.documentElement.style.setProperty(
      '--buttonClose-PosCrossSm',
      this.config.closeCornerButtonPosCrossSm || null,
    );
    document.documentElement.style.setProperty(
      '--buttonClose-PosCrossXs',
      this.config.closeCornerButtonPosCrossXs || null,
    );
    document.documentElement.style.setProperty(
      '--buttonClose-PosCrossXss',
      this.config.closeCornerButtonPosCrossXss || null,
    );
  }

  /**
   * Función encargada de cargar la configuración de las propiedades Scss de alineación de los botones en el modal
   */
  private loadConfigurationScssPropertiesButtons(): void {
    document.documentElement.style.setProperty('--top-buttonsGapValue', this.config.topButtonsGapValue || null);
    document.documentElement.style.setProperty('--bottom-buttonsGapValue', this.config.bottomButtonsGapValue || null);
    document.documentElement.style.setProperty('--buttons-betweenGap', this.config.buttonsBetweenGap || null);
    document.documentElement.style.setProperty('--buttonNext-WidthXl', this.config.buttonNextWidthXl || null);
    document.documentElement.style.setProperty('--buttonNext-WidthLg', this.config.buttonNextWidthLg || null);
    document.documentElement.style.setProperty('--buttonNext-WidthMd', this.config.buttonNextWidthMd || null);
    document.documentElement.style.setProperty('--buttonNext-WidthSm', this.config.buttonNextWidthSm || null);
    document.documentElement.style.setProperty('--buttonNext-WidthXs', this.config.buttonNextWidthXs || null);
    document.documentElement.style.setProperty('--buttonNext-WidthXss', this.config.buttonNextWidthXss || null);
    document.documentElement.style.setProperty('--buttonNext-HeightXl', this.config.buttonNextHeightXl || null);
    document.documentElement.style.setProperty('--buttonNext-HeightLg', this.config.buttonNextHeightLg || null);
    document.documentElement.style.setProperty('--buttonNext-HeightMd', this.config.buttonNextHeightMd || null);
    document.documentElement.style.setProperty('--buttonNext-HeightSm', this.config.buttonNextHeightSm || null);
    document.documentElement.style.setProperty('--buttonNext-HeightXs', this.config.buttonNextHeightXs || null);
    document.documentElement.style.setProperty('--buttonNext-HeightXss', this.config.buttonNextHeightXss || null);
  }
  /**
   * Metodo que muestra el modal de dialogo para confirmar la cancelación del agendamiento
   */
  public openModalCancelApproved() {
    this.dialog.open(CustomModalComponent, {
      data: this.configModalsModifyCancel.modalCancelApproved(),
      width: '720px',
      height: '326px',
      panelClass: 'dialog-container',
      autoFocus: false,
      restoreFocus: false,
      disableClose: true,
    });
  }
  /**
   * Metodo que muestra el modal de dialogo para cancelar la cancelación del agendamiento
   */
  public openModalCancel() {
    this.dialog.open(CustomModalComponent, {
      data: this.configModalsModifyCancel.modalCancel(),
      width: '720px',
      height: '355px',
      panelClass: 'dialog-container',
      autoFocus: false,
      restoreFocus: false,
      disableClose: true,
    });
  }
  /**
   * Convierte string a json
   * @param data cadena a separar @see string
   */
  public parseJson(data: string) {
    return JSON.parse(data);
  }

  /**
   * Metodo que gestiona las acciones para el botón de cerrar
   */
  public onSubmitClose(option: string | undefined = '') {
    this.spinner.show()
    if (option === 'Root') this.router.navigate(['/']);
    this.spinner.hide();
  }
}
