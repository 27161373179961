import { CustomModalModel } from '../../models/custom-modal.model';

/**
 * Componentes modales para el modulo de asignación de citas validacionces
 */
export class ConfigModalsAssignmentValidation {
  /**
   * Configuración del modal
   */
  private readonly configDialog: CustomModalModel = {};
  /**
   * Metodo encargado de crear un componente modal
   */
  public modalBrowserOpera(): CustomModalModel {
    this.configDialog.closeCornerButton = false;
    this.configDialog.closeCornerButtonPosAxisXl = ' 679px';
    this.configDialog.closeCornerButtonPosCrossXl = '-45px';
    this.configDialog.closeCornerButtonPosAxisLg = '679px';
    this.configDialog.closeCornerButtonPosCrossLg = '-45px';
    this.configDialog.closeCornerButtonPosAxisMd = '76%';
    this.configDialog.closeCornerButtonPosCrossMd = '-45px';
    this.configDialog.closeCornerButtonPosAxisSm = '74%';
    this.configDialog.closeCornerButtonPosCrossSm = '-45px';
    this.configDialog.closeCornerButtonPosAxisXs = '73%';
    this.configDialog.closeCornerButtonPosCrossXs = '-45px';
    this.configDialog.closeCornerButtonPosAxisXss = '72%';
    this.configDialog.closeCornerButtonPosCrossXss = '-45px';
    this.configDialog.textContentModal =
      '<p>Apreciado afiliado, actualmente estamos trabajando para ofrecerte un mejor servicio desde tu celular iOS y computador MAC, por ahora, te invitamos a agendar tus citas a través de otros dispositivos.<p>';
    this.configDialog.sizeTextContentModalXl = '24px';
    this.configDialog.sizeTextContentModalLg = '24px';
    this.configDialog.sizeTextContentModalMd = '24px';
    this.configDialog.sizeTextContentModalSm = '22px';
    this.configDialog.sizeTextContentModalXs = '22px';
    this.configDialog.sizeTextContentModalXss = '20px';
    this.configDialog.topTextContentGapValue = '2%';
    this.configDialog.bottomTextContentGapValue = '1%';
    this.configDialog.typeContentModal = 'text';
    this.configDialog.typeTemplate = 'cancel';
    this.configDialog.contentImage = true;
    this.configDialog.locationImage = 'bottom';
    this.configDialog.image = 'warning.png';
    this.configDialog.widthImageXl = '91px';
    this.configDialog.widthImageLg = '91px';
    this.configDialog.widthImageMd = '91px';
    this.configDialog.widthImageSm = '91px';
    this.configDialog.widthImageXs = '50px';
    this.configDialog.widthImageXss = '50px';
    this.configDialog.heightImageXl = '91px';
    this.configDialog.heightImageLg = '91px';
    this.configDialog.heightImageMd = '91px';
    this.configDialog.heightImageSm = '91px';
    this.configDialog.heightImageXs = '50px';
    this.configDialog.heightImageXss = '50px';
    this.configDialog.topImageGapValue = '20px';
    this.configDialog.bottomImageGapValue = '10px';
    this.configDialog.buttonNext = true;
    this.configDialog.caseButtonNext = 'Default';
    this.configDialog.textNextButton = 'Volver';
    this.configDialog.buttonNextWidthXl = '158px';
    this.configDialog.buttonNextWidthLg = '158px';
    this.configDialog.buttonNextWidthMd = '158px';
    this.configDialog.buttonNextWidthSm = '158px';
    this.configDialog.buttonNextWidthXs = '158px';
    this.configDialog.buttonNextWidthXss = '158px';

    return this.configDialog;
  }

  /**
   * Metodo encargado de crear un componente modal de mesaje de respuesta
   */
  public modalErrorResponse(message: string): CustomModalModel {
    this.configDialog.closeCornerButton = true;
    this.configDialog.closeCornerButtonPosAxisXl = '679px';
    this.configDialog.closeCornerButtonPosCrossXl = '-45px';
    this.configDialog.closeCornerButtonPosAxisLg = '679px';
    this.configDialog.closeCornerButtonPosCrossLg = '-45px';
    this.configDialog.closeCornerButtonPosAxisMd = '76%';
    this.configDialog.closeCornerButtonPosCrossMd = '-45px';
    this.configDialog.closeCornerButtonPosAxisSm = '74%';
    this.configDialog.closeCornerButtonPosCrossSm = '-45px';
    this.configDialog.closeCornerButtonPosAxisXs = '73%';
    this.configDialog.closeCornerButtonPosCrossXs = '-45px';
    this.configDialog.closeCornerButtonPosAxisXss = '72%';
    this.configDialog.closeCornerButtonPosCrossXss = '-45px';
    this.configDialog.textContentModal = `<p>${message}</p>`;
    this.configDialog.sizeTextContentModalXl = '24px';
    this.configDialog.sizeTextContentModalLg = '24px';
    this.configDialog.sizeTextContentModalMd = '24px';
    this.configDialog.sizeTextContentModalSm = '22px';
    this.configDialog.sizeTextContentModalXs = '22px';
    this.configDialog.sizeTextContentModalXss = '20px';
    this.configDialog.topTextContentGapValue = '2%';
    this.configDialog.bottomTextContentGapValue = '1%';
    this.configDialog.typeContentModal = 'text';
    this.configDialog.typeTemplate = 'cancel';
    this.configDialog.contentImage = true;
    this.configDialog.locationImage = 'bottom';
    this.configDialog.image = 'warning.png';
    this.configDialog.widthImageXl = '91px';
    this.configDialog.widthImageLg = '91px';
    this.configDialog.widthImageMd = '91px';
    this.configDialog.widthImageSm = '91px';
    this.configDialog.widthImageXs = '91px';
    this.configDialog.widthImageXss = '91px';
    this.configDialog.heightImageXl = '91px';
    this.configDialog.heightImageLg = '91px';
    this.configDialog.heightImageMd = '91px';
    this.configDialog.heightImageSm = '91px';
    this.configDialog.heightImageXs = '91px';
    this.configDialog.heightImageXss = '91px';
    this.configDialog.topImageGapValue = '20px';
    this.configDialog.bottomImageGapValue = '50px';
    this.configDialog.buttonNext = true;
    this.configDialog.caseButtonNext = 'Default';
    this.configDialog.textNextButton = 'Aceptar';
    this.configDialog.buttonNextWidthXl = '158px';
    this.configDialog.buttonNextWidthLg = '158px';
    this.configDialog.buttonNextWidthMd = '158px';
    this.configDialog.buttonNextWidthSm = '158px';
    this.configDialog.buttonNextWidthXs = '158px';
    this.configDialog.buttonNextWidthXss = '158px';

    return this.configDialog;
  }

  /**
   * Metodo encargado de crear un componente modal de mesaje de respuesta
   */
  public modalSuccessResponse(message: string): CustomModalModel {
    this.configDialog.closeCornerButton = true;
    this.configDialog.closeCornerButtonPosAxisXl = '679px';
    this.configDialog.closeCornerButtonPosCrossXl = '-45px';
    this.configDialog.closeCornerButtonPosAxisLg = '679px';
    this.configDialog.closeCornerButtonPosCrossLg = '-45px';
    this.configDialog.closeCornerButtonPosAxisMd = '76%';
    this.configDialog.closeCornerButtonPosCrossMd = '-45px';
    this.configDialog.closeCornerButtonPosAxisSm = '74%';
    this.configDialog.closeCornerButtonPosCrossSm = '-45px';
    this.configDialog.closeCornerButtonPosAxisXs = '73%';
    this.configDialog.closeCornerButtonPosCrossXs = '-45px';
    this.configDialog.closeCornerButtonPosAxisXss = '72%';
    this.configDialog.closeCornerButtonPosCrossXss = '-45px';
    this.configDialog.textContentModal = `<p>${message}</p>`;
    this.configDialog.sizeTextContentModalXl = '24px';
    this.configDialog.sizeTextContentModalLg = '24px';
    this.configDialog.sizeTextContentModalMd = '24px';
    this.configDialog.sizeTextContentModalSm = '22px';
    this.configDialog.sizeTextContentModalXs = '22px';
    this.configDialog.sizeTextContentModalXss = '20px';
    this.configDialog.topTextContentGapValue = '2%';
    this.configDialog.bottomTextContentGapValue = '1%';
    this.configDialog.typeContentModal = 'text';
    this.configDialog.typeTemplate = 'cancel';
    this.configDialog.contentImage = true;
    this.configDialog.locationImage = 'bottom';
    this.configDialog.image = 'success.png';
    this.configDialog.widthImageXl = '91px';
    this.configDialog.widthImageLg = '91px';
    this.configDialog.widthImageMd = '91px';
    this.configDialog.widthImageSm = '91px';
    this.configDialog.widthImageXs = '91px';
    this.configDialog.widthImageXss = '91px';
    this.configDialog.heightImageXl = '91px';
    this.configDialog.heightImageLg = '91px';
    this.configDialog.heightImageMd = '91px';
    this.configDialog.heightImageSm = '91px';
    this.configDialog.heightImageXs = '91px';
    this.configDialog.heightImageXss = '91px';
    this.configDialog.topImageGapValue = '20px';
    this.configDialog.bottomImageGapValue = '50px';
    this.configDialog.buttonNext = true;
    this.configDialog.caseButtonNext = 'Default';
    this.configDialog.textNextButton = 'Aceptar';
    this.configDialog.buttonNextWidthXl = '158px';
    this.configDialog.buttonNextWidthLg = '158px';
    this.configDialog.buttonNextWidthMd = '158px';
    this.configDialog.buttonNextWidthSm = '158px';
    this.configDialog.buttonNextWidthXs = '158px';
    this.configDialog.buttonNextWidthXss = '158px';

    return this.configDialog;
  }
  /**
   * Metodo encargado de crear un componente modal de mesaje de respuesta
   */
  public modalDataConsumer(): CustomModalModel {
    this.configDialog.closeCornerButton = true;
    this.configDialog.closeCornerButtonPosAxisXl = '679px';
    this.configDialog.closeCornerButtonPosCrossXl = '-45px';
    this.configDialog.closeCornerButtonPosAxisLg = '679px';
    this.configDialog.closeCornerButtonPosCrossLg = '-45px';
    this.configDialog.closeCornerButtonPosAxisMd = '76%';
    this.configDialog.closeCornerButtonPosCrossMd = '-45px';
    this.configDialog.closeCornerButtonPosAxisSm = '74%';
    this.configDialog.closeCornerButtonPosCrossSm = '-45px';
    this.configDialog.closeCornerButtonPosAxisXs = '73%';
    this.configDialog.closeCornerButtonPosCrossXs = '-45px';
    this.configDialog.closeCornerButtonPosAxisXss = '72%';
    this.configDialog.closeCornerButtonPosCrossXss = '-45px';
    this.configDialog.textContentModal = `<p>Cuentas con un Defensor del Consumidor Financiero, Dra. Ana María Giraldo Rincón ubicado en: <br/><br/>Carrera 10 # 97A-13 Oficina 502 en Bogotá <br/>Teléfono: 601 610 81 64 <br/>Correo: defensoriaporvenir@legalcrc.com <br/><br/>Quien dará trámite a tu queja de forma objetiva y gratuita.</p>`;
    this.configDialog.sizeTextContentModalXl = '24px';
    this.configDialog.sizeTextContentModalLg = '24px';
    this.configDialog.sizeTextContentModalMd = '24px';
    this.configDialog.sizeTextContentModalSm = '22px';
    this.configDialog.sizeTextContentModalXs = '22px';
    this.configDialog.sizeTextContentModalXss = '20px';
    this.configDialog.topTextContentGapValue = '2%';
    this.configDialog.bottomTextContentGapValue = '1%';
    this.configDialog.typeContentModal = 'text';
    this.configDialog.typeTemplate = 'cancel';
    this.configDialog.contentImage = false;
    this.configDialog.locationImage = 'bottom';
    this.configDialog.widthImageXl = '91px';
    this.configDialog.widthImageLg = '91px';
    this.configDialog.widthImageMd = '91px';
    this.configDialog.widthImageSm = '91px';
    this.configDialog.widthImageXs = '91px';
    this.configDialog.widthImageXss = '91px';
    this.configDialog.heightImageXl = '91px';
    this.configDialog.heightImageLg = '91px';
    this.configDialog.heightImageMd = '91px';
    this.configDialog.heightImageSm = '91px';
    this.configDialog.heightImageXs = '91px';
    this.configDialog.heightImageXss = '91px';
    this.configDialog.topImageGapValue = '20px';
    this.configDialog.bottomImageGapValue = '50px';
    this.configDialog.buttonNext = true;
    this.configDialog.caseButtonNext = 'Default';
    this.configDialog.textNextButton = 'Continuar';
    this.configDialog.buttonNextWidthXl = '158px';
    this.configDialog.buttonNextWidthLg = '158px';
    this.configDialog.buttonNextWidthMd = '158px';
    this.configDialog.buttonNextWidthSm = '158px';
    this.configDialog.buttonNextWidthXs = '158px';
    this.configDialog.buttonNextWidthXss = '158px';

    return this.configDialog;
  }

  /**
   * Metodo encargado de crear un componente modal para el pop up desplegado al seleccionar doble asesoría.
   */
  public modalPopupDoubleAdvise(): CustomModalModel{
    this.configDialog.closeCornerButton = true;
    this.configDialog.closeCornerButtonPosAxisXl = '679px';
    this.configDialog.closeCornerButtonPosCrossXl = '-45px';
    this.configDialog.closeCornerButtonPosAxisLg = '679px';
    this.configDialog.closeCornerButtonPosCrossLg = '-45px';
    this.configDialog.closeCornerButtonPosAxisMd = '76%';
    this.configDialog.closeCornerButtonPosCrossMd = '-45px';
    this.configDialog.closeCornerButtonPosAxisSm = '74%';
    this.configDialog.closeCornerButtonPosCrossSm = '-45px';
    this.configDialog.closeCornerButtonPosAxisXs = '73%';
    this.configDialog.closeCornerButtonPosCrossXs = '-45px';
    this.configDialog.closeCornerButtonPosAxisXss = '72%';
    this.configDialog.closeCornerButtonPosCrossXss = '-45px';
    this.configDialog.textContentModal = `<p>Ahora podrás acceder a nuestra Doble Asesoría de inmediato a través de nuestra Línea de Servicio. Solo necesitas contar con 30 minutos y estarás listo para recibirla. ¡Contáctanos ahora mismo en:<br><br>•	Bogotá: 601 744 76 78 <br>• Cali: 602 485 72 72 <br>•	Barranquilla: 605 385 51 51 <br>•	Medellín: 604 604 15 55<br>•	Resto de Colombia: 01 8000 510 800 <br>•	Resto de Colombia desde celular: #857</p>`;
    this.configDialog.sizeTextContentModalXl = '22px';
    this.configDialog.sizeTextContentModalLg = '22px';
    this.configDialog.sizeTextContentModalMd = '22px';
    this.configDialog.sizeTextContentModalSm = '20px';
    this.configDialog.sizeTextContentModalXs = '20px';
    this.configDialog.sizeTextContentModalXss = '18px';
    this.configDialog.topTextContentGapValue = '2%';
    this.configDialog.bottomTextContentGapValue = '1%';
    this.configDialog.typeContentModal = 'text';
    this.configDialog.typeTemplate = 'cancel';
    this.configDialog.buttonNext = true;
    this.configDialog.caseButtonNext = 'Aceptar';
    this.configDialog.textNextButton = 'Aceptar';
    this.configDialog.buttonNextWidthXl = '158px';
    this.configDialog.buttonNextWidthLg = '158px';
    this.configDialog.buttonNextWidthMd = '158px';
    this.configDialog.buttonNextWidthSm = '158px';
    this.configDialog.buttonNextWidthXs = '158px';
    this.configDialog.buttonNextWidthXss = '158px';

    return this.configDialog;
  }

  /**
   * Metodo encargado de crear un componente modal para el pop up desplegado al seleccionar doble asesoría.
   */
  public modalMicrosite(): CustomModalModel{
    this.configDialog.closeCornerButton = true;
    this.configDialog.closeCornerButtonPosAxisXl = '679px';
    this.configDialog.closeCornerButtonPosCrossXl = '-45px';
    this.configDialog.closeCornerButtonPosAxisLg = '679px';
    this.configDialog.closeCornerButtonPosCrossLg = '-45px';
    this.configDialog.closeCornerButtonPosAxisMd = '76%';
    this.configDialog.closeCornerButtonPosCrossMd = '-45px';
    this.configDialog.closeCornerButtonPosAxisSm = '74%';
    this.configDialog.closeCornerButtonPosCrossSm = '-45px';
    this.configDialog.closeCornerButtonPosAxisXs = '73%';
    this.configDialog.closeCornerButtonPosCrossXs = '-45px';
    this.configDialog.closeCornerButtonPosAxisXss = '72%';
    this.configDialog.closeCornerButtonPosCrossXss = '-45px';
    this.configDialog.textContentModal = `<p>Aún no es el momento adecuado para que tomes tu Doble Asesoría. Si tienes inquietudes sobre los cambios relacionados con la Reforma Pensional, te invitamos a visitar nuestra página web en Porvenir.com.</p>`;
    this.configDialog.sizeTextContentModalXl = '22px';
    this.configDialog.sizeTextContentModalLg = '22px';
    this.configDialog.sizeTextContentModalMd = '22px';
    this.configDialog.sizeTextContentModalSm = '20px';
    this.configDialog.sizeTextContentModalXs = '20px';
    this.configDialog.sizeTextContentModalXss = '18px';
    this.configDialog.topTextContentGapValue = '2%';
    this.configDialog.bottomTextContentGapValue = '1%';
    this.configDialog.typeContentModal = 'text';
    this.configDialog.typeTemplate = 'cancel';
    this.configDialog.buttonNext = true;
    this.configDialog.caseButtonNext = 'Micrositio';
    this.configDialog.caseButtonClose = 'Root';
    this.configDialog.textNextButton = 'Aceptar';
    this.configDialog.buttonNextWidthXl = '158px';
    this.configDialog.buttonNextWidthLg = '158px';
    this.configDialog.buttonNextWidthMd = '158px';
    this.configDialog.buttonNextWidthSm = '158px';
    this.configDialog.buttonNextWidthXs = '158px';
    this.configDialog.buttonNextWidthXss = '158px';

    return this.configDialog;
  }

  /**
   * Metodo encargado de crear un componente modal para el pop up desplegado al seleccionar doble asesoría.
   */
  public modalModifyDoubleAdvise(): CustomModalModel{
    this.configDialog.closeCornerButton = true;
    this.configDialog.closeCornerButtonPosAxisXl = '679px';
    this.configDialog.closeCornerButtonPosCrossXl = '-45px';
    this.configDialog.closeCornerButtonPosAxisLg = '679px';
    this.configDialog.closeCornerButtonPosCrossLg = '-45px';
    this.configDialog.closeCornerButtonPosAxisMd = '76%';
    this.configDialog.closeCornerButtonPosCrossMd = '-45px';
    this.configDialog.closeCornerButtonPosAxisSm = '74%';
    this.configDialog.closeCornerButtonPosCrossSm = '-45px';
    this.configDialog.closeCornerButtonPosAxisXs = '73%';
    this.configDialog.closeCornerButtonPosCrossXs = '-45px';
    this.configDialog.closeCornerButtonPosAxisXss = '72%';
    this.configDialog.closeCornerButtonPosCrossXss = '-45px';
    this.configDialog.textContentModal = `<p>Para modificar tu cita y agendar tu Doble Asesoría, por favor comunícate con nuestra Línea de Servicio.<br>•	Bogotá: 601 7425454 <br>• Cali: 602 4857171 <br>•	Barranquilla: 605 3856363 <br>•	Medellín: 604 6043222<br>•	Resto de Colombia: 018000518440</p>`;
    this.configDialog.sizeTextContentModalXl = '22px';
    this.configDialog.sizeTextContentModalLg = '22px';
    this.configDialog.sizeTextContentModalMd = '22px';
    this.configDialog.sizeTextContentModalSm = '20px';
    this.configDialog.sizeTextContentModalXs = '20px';
    this.configDialog.sizeTextContentModalXss = '18px';
    this.configDialog.topTextContentGapValue = '2%';
    this.configDialog.bottomTextContentGapValue = '1%';
    this.configDialog.typeContentModal = 'text';
    this.configDialog.typeTemplate = 'cancel';
    this.configDialog.buttonNext = true;
    this.configDialog.caseButtonClose = 'Root';
    this.configDialog.caseButtonNext = 'Salir';
    this.configDialog.textNextButton = 'Aceptar';
    this.configDialog.buttonNextWidthXl = '158px';
    this.configDialog.buttonNextWidthLg = '158px';
    this.configDialog.buttonNextWidthMd = '158px';
    this.configDialog.buttonNextWidthSm = '158px';
    this.configDialog.buttonNextWidthXs = '158px';
    this.configDialog.buttonNextWidthXss = '158px';

    return this.configDialog;
  }

}
