import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { InteractionApiService, InteractionDto } from '@prv/interaction';
import { DataExternalDto } from '@prv/interaction/lib/models/data-external.dto';
import { ScheduleDto } from '@prv/schedule-history';
import { DetailReasonStateSchedulingDto } from '@prv/scheduling-state';
import { CcmApiService, SmsRequestDto } from '@prv/ws-ccm';
import { InteractionCrmApiService, RequestDto } from '@prv/ws-interaction';
import * as moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppModuleState } from '../../../app.state';
import { EncryptService } from '../../services/encrypt/encrypt.service';
import { TypeInteraction } from '../../models/enum-type-interaction/type-interaction.enum';

/**
 * Capa intermedia que centraliza la logica del modulo de consulta
 */
@Injectable()
export class CustomModalFacade implements OnDestroy {
  /**
   * State de schedule
   */
  private readonly state: AppModuleState;
  /**
   * Llamada al interactionApiService
   */
  private readonly interactionApiService: InteractionApiService;
  /**
   * Llamada al interactionApiService
   */
  private readonly interactionCrmApiService: InteractionCrmApiService;
  /**
   * Servicio encargado de interactuar con el microservicio de interaccion
   */
  private readonly ccmApiService: CcmApiService;
  /**
   * Servicio encargado de interactuar con el servicio de encriptacion
   */
  private readonly encryptService: EncryptService;
  /**
   * Provides access to information about a route associated with a component that is loaded in an outlet.
   */
  private readonly router: Router;
  /**
   * cancelar suscripciones
   */
  private readonly subscription: Subscription;
  /**
   * Observable encargado de eliminar los diferentes sucriptores
   */
  public destroy$ = new Subject<boolean>();
  /**
   * Almacenar el observable
   */
  public datos: any;
  /**
   * Almacenar la Opcion de cancelacion
   */
  public optionCancel: string;
  /**
   * Observable de schedules
   */
  public readonly detailReasonStateScheduling$ = (): Observable<DetailReasonStateSchedulingDto[]> =>
    this.state.detailReasonStateScheduling$

  /**
   * Observable de schedules
   */
  public readonly schedule$ = (): Observable<ScheduleDto> => this.state.scheduleId$;

  /**
   * Crea una nueva instancia de SearchFacade
   */
  public constructor(
    state: AppModuleState,
    interactionApiService: InteractionApiService,
    interactionCrmApiService: InteractionCrmApiService,
    ccmApiService: CcmApiService,
    router: Router,
    encryptService: EncryptService,
  ) {
    this.state = state;
    this.interactionApiService = interactionApiService;
    this.interactionCrmApiService = interactionCrmApiService;
    this.ccmApiService = ccmApiService;
    this.router = router;
    this.encryptService = encryptService;
    this.subscription = new Subscription();
    this.datos = {};
    this.optionCancel = '';
  }
  /**
   * A callback method that performs custom clean-up, invoked immediately
   * before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroySubscription();
    setTimeout(() => {
      this.destroy$.next();
      this.destroy$.complete();
    }, 3000);
  }
  /**
   * Metodo encargado de desuscribir los observables de la facade
   */
  public destroySubscription(): void {
    this.subscription.unsubscribe();
  }
  /**
   * Metodo encargado de enviar de enviar la interacion y cancelar el agendamiento
   */
  public cancelShedule(fn: () => void) {
    this.optionCancel = localStorage.getItem('OptionCancel') || '';
    this.datos = this.parseJson(localStorage.getItem('Datos') || '');
    localStorage.removeItem('OptionCancel');
    localStorage.removeItem('Datos');

    const payloadInteraction = {
      id: this.datos.id,
      ipPc: localStorage.getItem('ip')?.replace(/['"]+/g, '') || '',
      isValid: true,
      isSubscribed: true,
      dateCall: new Date(),
      dateScheduling: this.datos.dateScheduling,
      timeScheduling: this.datos.dateScheduling,

      typify: {
        id: this.parseJson(this.datos.payload).typify.id,
        idHomolog: this.parseJson(this.datos.payload).typify.idHomolog,
        guid: this.parseJson(this.datos.payload).typify.guid,
        name: this.parseJson(this.datos.payload).typify.name,
        colorHexadecimal: this.parseJson(this.datos.payload).typify.colorHexadecimal,
        maxTimeMinutes: this.parseJson(this.datos.payload).typify.maxTimeMinutes,
        isValid: this.parseJson(this.datos.payload).typify.isValid,
        structure: this.parseJson(this.datos.payload).typify.structure,
        metadata: this.parseJson(this.datos.payload).typify.metadata,
      },
      cancelation: {
        nameDetailReasonByAfiliado: this.optionCancel,
      },
      contact: {
        isComplete: this.parseJson(this.datos.payload).contact.isComplete,
        disabledFields: this.parseJson(this.datos.payload).contact.disabledFields,
        hideFields: this.parseJson(this.datos.payload).contact.hideFields,
        id: this.parseJson(this.datos.payload).contact.id,
        idTypeDocument: this.parseJson(this.datos.payload).contact.idTypeDocument,
        numberDocument: this.parseJson(this.datos.payload).contact.numberDocument,
        firstNames: this.parseJson(this.datos.payload).contact.firstNames,
        lastNames: this.parseJson(this.datos.payload).contact.lastNames,
        fullName: this.parseJson(this.datos.payload).contact.fullName,
        idGender: this.parseJson(this.datos.payload).contact.idGender,
        gender: this.parseJson(this.datos.payload).contact.gender,
        birthDate: this.parseJson(this.datos.payload).contact.birthDate,
        cellPhones: this.parseJson(this.datos.payload).contact.cellPhones,
        localPhones: this.parseJson(this.datos.payload).contact.localPhones,
        emails: this.parseJson(this.datos.payload).contact.emails,
        address: this.parseJson(this.datos.payload).contact.address,
        habeasData: true,
        typeDocument: this.parseJson(this.datos.payload).contact.typeDocument,
        typeContact: this.parseJson(this.datos.payload).contact.typeContact,
        additionalInformation: this.parseJson(this.datos.payload).contact.additionalInformation,
        city: this.parseJson(this.datos.payload).contact.city,
      },
      assitent: {
        isComplete: this.parseJson(this.datos.payload).assitent.isComplete,
        disabledFields: this.parseJson(this.datos.payload).assitent.disabledFields,
        hideFields: this.parseJson(this.datos.payload).assitent.hideFields,
        id: this.parseJson(this.datos.payload).assitent.id,
        idTypeDocument: this.parseJson(this.datos.payload).assitent.idTypeDocument,
        numberDocument: this.parseJson(this.datos.payload).assitent.numberDocument,
        firstNames: this.parseJson(this.datos.payload).assitent.firstNames,
        lastNames: this.parseJson(this.datos.payload).assitent.lastNames,
        fullName: this.parseJson(this.datos.payload).assitent.fullName,
        idGender: this.parseJson(this.datos.payload).assitent.idGender,
        gender: this.parseJson(this.datos.payload).assitent.gender,
        birthDate: this.parseJson(this.datos.payload).assitent.birthDate,
        cellPhones: this.parseJson(this.datos.payload).assitent.cellPhones,
        localPhones: this.parseJson(this.datos.payload).assitent.localPhones,
        emails: this.parseJson(this.datos.payload).assitent.emails,
        address: this.parseJson(this.datos.payload).assitent.address,
        habeasData: true,
        typeDocument: this.parseJson(this.datos.payload).assitent.typeDocument,
        typeContact: this.parseJson(this.datos.payload).assitent.typeContact,
        additionalInformation: this.parseJson(this.datos.payload).assitent.additionalInformation,
      },
      stateScheduling: {
        id: 3,
        name: 'Cancelado',
      },
      channel: {
        id: this.parseJson(this.datos.payload).channel.id,
        name: this.parseJson(this.datos.payload).channel.name,
      },
      office: {
        id: this.parseJson(this.datos.payload).office.id,
        name: this.parseJson(this.datos.payload).office.name,
        typeOffice: this.parseJson(this.datos.payload).office.typeOffice,
      },
      userCreator: {
        id: this.parseJson(this.datos.payload).userCreator.id,
        userName: this.parseJson(this.datos.payload).userCreator.userName,
        idServant: this.parseJson(this.datos.payload).userCreator.idServant,
        idPosition: this.parseJson(this.datos.payload).userGestorAssigned.idPosition,
        position: this.parseJson(this.datos.payload).userCreator.position,
        fullName: this.parseJson(this.datos.payload).userCreator.fullName,
        token: this.parseJson(this.datos.payload).userCreator.token,
        office: this.parseJson(this.datos.payload).userCreator.office,
      },
      userGestorAssigned: {
        id: this.parseJson(this.datos.payload).userGestorAssigned.id,
        userName: this.parseJson(this.datos.payload).userGestorAssigned.userName,
        idServant: this.parseJson(this.datos.payload).userGestorAssigned.idServant,
        idPosition: this.parseJson(this.datos.payload).userGestorAssigned.idPosition,
        position: this.parseJson(this.datos.payload).userGestorAssigned.position,
        fullName: this.parseJson(this.datos.payload).userGestorAssigned.fullName,
        token: this.parseJson(this.datos.payload).userGestorAssigned.token,
        office: this.parseJson(this.datos.payload).userGestorAssigned.office,
      },
    };

    const interactionDto: InteractionDto = {
      idChannel: 1,
      idContact: 0,
      idSequence: 2,
      ipPc: localStorage.getItem('ip')?.replace(/['"]+/g, '') || '',
      version: '1.0',
      payload: `{"payload": ${JSON.stringify(payloadInteraction)}, "version": "1.0"}`,
      startDate: new Date(),
      endDate: new Date(),
      state: true,
      idUserCreator: 0,
      dateCreated: new Date(),
    };

    const dataSerialized = JSON.stringify(interactionDto);
    const dataEncrypted = { data: this.encryptService.toEncrypt(dataSerialized) } as DataExternalDto;
    this.interactionApiService.postExternal(dataEncrypted)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        fn();
      });
  }

  /**
   * Envia los datos al Ws de Intercación de CRM Porvenir
   */
  public setWsinteraction() {
    const wsInteractionDto: RequestDto = {
      tipoIdCliente: this.parseJson(this.datos.payload).assitent.typeDocument.mark,
      numIdCliente: this.parseJson(this.datos.payload).assitent.numberDocument,
      tipoInteraccion: TypeInteraction.CANCELLATION,
      comentario: `Detalle de la interacción:
      Fecha y Hora: ${moment(this.datos.dateScheduling).locale('es').format('dddd DD [de] MMMM')} / ${moment(
        this.datos.timeScheduling,
      )
        .locale('es')
        .format('HH:mm a')}
      Asesor: ${this.parseJson(this.datos.payload).userGestorAssigned.fullName}
      Oficina: ${this.parseJson(this.datos.payload).office.typeOffice}
      Observacion: ${this.optionCancel}
      Creado Por ${this.parseJson(this.datos.payload).userCreator.name} ${
        this.parseJson(this.datos.payload).userCreator.address
      }
      Tipo: Manual`,
    };

    const dataSerialized = JSON.stringify(wsInteractionDto);
    const dataEncrypted = { data: this.encryptService.toEncrypt(dataSerialized) } as DataExternalDto;

    this.interactionCrmApiService.postInteractionExtenal(dataEncrypted)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.sendCcm(
          {
            cardId: '5fed1e2c995f340b3435283f',
            customers: [
              {
                data: {
                  tipoPersona: 'Afiliado',
                  nombres: this.parseJson(this.datos.payload).assitent.fullName,
                  apellidos: this.parseJson(this.datos.payload).assitent.fullName,
                  email: this.parseJson(this.datos.payload).assitent.emails[0],
                  celular: this.parseJson(this.datos.payload).assitent.cellPhones[0],
                },
                id: this.datos.numberDocumentAssistant,
                typeId: localStorage.getItem('AssitantTypeDocument') || 'CC',
              },
            ],
          },
          () => {},
        );
      });
    localStorage.clear();
    this.router.navigate(['']);
  }

  /**
   * Metodo encargado de consumir el servicio para emitir el SMS
   * @param data Objeto para enviar el SMS @see SmsRequestDto
   */
  public sendCcm(data: SmsRequestDto, fn: () => void) {
    this.ccmApiService.sendCcm(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => fn());
  }
  /**
   * Convierte string a json
   * @param data Cadena de payload @see string
   */
  public parseJson(data: string) {
    return JSON.parse(data);
  }
}
